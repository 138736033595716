<template>
  <v-row>
    <v-col v-if="transaction.status === 3" cols="12">
      <v-alert color="primary500" dense tile class="font-weight-semibold text-body-2 py-3">
        <fai :icon="['fas', 'exclamation-triangle']" class="mr-1" />
        This offer is currently being held for review, please wait.
      </v-alert>

      <v-btn
        v-if="isAdmin" small tile color="green700"
        @click="$emit('action', 'credit')"
      >
        <fai :icon="['fas', 'check']" class="mr-1" />
        Mark as reviewed and credit
      </v-btn>
    </v-col>
    <v-col cols="12" md="4">
      <h5 v-t="'page.account.transactions.detail.offers.id'" class="grey100--text text-body-2" />
      <p class="mb-0 text--secondary lh-1-2 text-body-2" v-text="transaction.referenceId" />
    </v-col>
    <v-col cols="12" md="4">
      <h5 v-t="'page.account.transactions.detail.offers.name'" class="grey100--text text-body-2" />
      <p class="mb-0 text--secondary lh-1-2 text-body-2" v-text="transaction.data.offerName" />
    </v-col>
    <v-col cols="12" md="4">
      <h5 v-t="'page.account.transactions.detail.offers.provider'" class="grey100--text text-body-2" />
      <p class="mb-0 text--secondary lh-1-4 text-body-2" v-text="provider" />
    </v-col>
  </v-row>
</template>


<script>
const PROVIDERS = {
  offertoro: 'OfferToro',
  lootably: 'Lootably',
}

export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  computed: {
    provider() {
      const { provider } = this.transaction?.data ?? {}
      return PROVIDERS[provider] ?? provider
    },
    isAdmin() {
      return this.user?.hasPermission('CAN_MODIFY_USERS')
    },
  },
}
</script>
